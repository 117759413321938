import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import './portfolio.css';

// Dodaj wszystkie importy obrazów poniżej (bez zmiany sposobu importowania)
import IMG0 from '../../assets/mockbioglow.png';
import IMG3 from '../../assets/portfoliog.jpg';
import IMG4 from '../../assets/mockpodlasie.png';
import IMG5 from '../../assets/portfolio2.jpg';
import IMG6 from '../../assets/portfolio1.jpg';
import IMG7 from '../../assets/portfolio12.jpg';
import IMG8 from '../../assets/portfolio14.jpg';
import IMG1 from '../../assets/mockmicha2.jpg';
import IMG2 from '../../assets/mockboxbufet.jpg';

const Portfolio = () => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const sliderRef = useRef(null);
  const touchStartX = useRef(null);
  const images = [IMG0, IMG1, IMG2, IMG3, IMG4, IMG5, IMG6, IMG7, IMG8];

  const handleSlideChange = (slideNumber) => {
    setCurrentSlide(slideNumber);
    const slideElement = document.getElementById(`slide-${slideNumber}`);
    if (slideElement) {
      slideElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  };

  const handlePrevSlide = () => {
    const prevSlide = currentSlide === 1 ? images.length : currentSlide - 1;
    handleSlideChange(prevSlide);
  };
  
  const handleNextSlide = () => {
    const nextSlide = currentSlide === images.length ? 1 : currentSlide + 1;
    handleSlideChange(nextSlide);
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const handleTouchEnd = (e) => {
    if (touchStartX.current - e.changedTouches[0].clientX > 50) {
      handleNextSlide();
    } else if (e.changedTouches[0].clientX - touchStartX.current > 50) {
      handlePrevSlide();
    }
    touchStartX.current = null;
  };

  useEffect(() => {
    setCurrentSlide(1);
  }, []);

  return (
    <section id='portfolio'>
      <h5>Moje przykładowe realizacje</h5>
      <h2>Portfolio</h2>
      <section className='container'>
        <div
          className='slider-wrapper'
          ref={sliderRef}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
        >
          <div className='slider-arrow left' onClick={handlePrevSlide}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </div>
          <div className='slider'>
            {images.map((img, index) => (
              <img
                key={index}
                id={`slide-${index + 1}`}
                src={img}
                alt={`Slide ${index + 1}`}
                style={{ width: '100%', display: index + 1 === currentSlide ? 'block' : 'none' }}
              />
            ))}
          </div>
          <div className='slider-arrow right' onClick={handleNextSlide}>
            <FontAwesomeIcon icon={faChevronRight} />
          </div>
          <div className='slider-info'>
            <p className='mobile-info'>Zmiana grafiki - przesuń w lewo lub prawo</p>
          </div>
        </div>
      </section>
    </section>
  );
};

export default Portfolio;
